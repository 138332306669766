var init = function(){
  console.log('init');

  $('.woof_select, select[name="orderby"]').select2({
    width: 'resolve',
    minimumResultsForSearch: 10

  });
  $(document).on("click", function(event){
    if( $(event.target).closest('.woof_price_search_container').length == 0) {
      $(".woof_redraw_zone .woof_price_search_container").removeClass('active');
    }
  });
  $(document).on("click", ".woof_redraw_zone .woof_price_search_container h4", function(){
    $(this).closest('.woof_price_search_container').toggleClass('active');
  })
}

$('.orphan-chars').each(function(){
  $(this).html($(this).html().replace(/\s([A-Za-z])\s([A-Za-z])\s/g, ' $1&nbsp;$2&nbsp;'));
  $(this).html($(this).html().replace(/\s([A-Za-z])\s/g, ' $1&nbsp;'));
  $(this).html($(this).html().replace(/\(([A-Za-z])\s/g, '($1&nbsp;'));
  $(this).html($(this).html().replace(/\)([A-Za-z])\s/g, ')$1&nbsp;'));
  $(this).html($(this).html().replace(/([0-9])\s([0-9])/g, '$1&nbsp;$2'));
  $(this).html($(this).html().replace(/\sr.\s/g, '&nbsp;r. '));
  $(this).html($(this).html().replace(/\szł/g, '&nbsp;zł'));
  $(this).html($(this).html().replace(/\sPLN/g, '&nbsp;PLN'));
});

makeSvgInline = function($img){
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(imgURL, function(data) {
    // Get the SVG tag, ignore the rest
    var $svg = jQuery(data).find('svg');

    // Add replaced image's ID to the new SVG
    if(typeof imgID !== 'undefined') {
      $svg = $svg.attr('id', imgID);
    }
    // Add replaced image's classes to the new SVG
    if(typeof imgClass !== 'undefined') {
      $svg = $svg.attr('class', imgClass+' replaced-svg');
    }

    // Remove any invalid XML tags as per http://validator.w3.org
    $svg = $svg.removeAttr('xmlns:a');

    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
      $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
    }

    // Replace image with new SVG
    $img.replaceWith($svg);
  }, 'xml');
};
$(window).on('scroll load', function(){
  if( $(".archive .aside.asid__categories .aside_scrolling").length > 0 ) {

    var sTop = $(this).scrollTop() + $("header.header").outerHeight(),
      sidebar = $(".aside.asid__categories .aside_scrolling"),
      offset = sidebar.parent().offset().top,
      max = sidebar.parent().outerHeight(),
      padding = sTop - offset;

    if (padding >= 0) {
      if (padding + sidebar.innerHeight() < max)
        sidebar.parent().css('padding-top', padding);
    } else {
      sidebar.parent().css('padding-top', 0);
    }
  }
});

function langSwitcher() {
  const switcher = $('.lang-switcher');

  if (switcher) {
    switcher.on('click', function (e) {
      $(this).toggleClass('is-active');
    });
    window.addEventListener('click', e => {
      if (e.target.classList.contains('lang-switcher') || e.target.classList.contains('lang-item')) {
      } else {
        switcher.removeClass('is-active');
      }
    })
  }
};

function searchBox() {
  const search = $('.search-box');
  const searchInputWrapper = $('.search-box__inputWrapper');

  search.on('click', function (e) {
    search.addClass('active');
    searchInputWrapper.find('#search').focus();
    searchInputWrapper.animate({
      opacity: 1,
    }, 200);
  })

  function checkAndSubmit(searchInput) {
    if (searchInput.val() != '') {
      searchInput.parent('form').submit()
    } else {
      searchInput.attr('placeholder', 'Pole wyszukiwania nie może być puste');
      searchInput.addClass('error');
      setTimeout(function() {
        searchInput.attr('placeholder', 'Szukaj ..');
        searchInput.removeClass('error');
      }, 2000)
    }
  }

  searchInputWrapper.find('.search-icon').on('click', function(e) {
    e.preventDefault();
    checkAndSubmit($(e.target).closest('.search-box__inputWrapper').find('.search-input'));
  })

  $('.search-clear').on('click', function (e) {
    e.stopPropagation();
    $('#search').val('');
    search.removeClass('active');
    searchInputWrapper.css('opacity', 0);
  })

  $(document).on('click', function (e) {
    if (!search.is(e.target) && search.has(e.target).length === 0 && $('.search-input').val().length === 0) {
      search.removeClass('active');
      searchInputWrapper.css('opacity', 0);
    }
  })
}

$(function(){
  $(document).on("click", ".select-all", function (e) {
    e.preventDefault();
    let checkedAll = $(this).data('checkedall'),
        uncheckedAll = $(this).data('uncheckedall');

    if (!$(this).hasClass('checked')) {
        $(this).addClass('checked');
        $(this).text(uncheckedAll);
        $(this).parents('form').find('input:checkbox').prop('checked', true);
    } else {
        $(this).removeClass('checked');
        $(this).text(checkedAll);
        $(this).parents('form').find('input:checkbox').prop('checked', false);
    }
  });

  if ($("input[name='billing_postcode']").length > 0 || $("input[name='shipping_postcode']").length > 0) {
    $("input[name='billing_postcode'], input[name='shipping_postcode']").mask("99-999", {autoclear:true,placeholder: 'xx-xxx'});
  }

  if ($("input[name='shipping_vat_number']").length > 0 ) {
    $("input[name='shipping_vat_number']").mask("9999999999", {autoclear:true,placeholder: 'xxxxxxxxxx'});
  }

  // make svg inline
  $('img.svg').each(function(){
    var $img = jQuery(this);
    makeSvgInline($img);
  });
});

function dividerHeight() {
  let divider = $('.js-divider');
  function setDividerHeight() {
    divider.each(function (i, e) {
      let elem = $(this),
          dHeight = elem.data('dheight')/10 + 'rem',
          tHeight = elem.data('theight')/10 + 'rem',
          mHeight = elem.data('mheight')/10 + 'rem';
      if ($( window ).width() >= 1281) {
        elem.css('padding-bottom', dHeight);
      } else if ($( window ).width() <= 1280 && $( window ).width() >= 769) {
        elem.css('padding-bottom', tHeight);
      } else {
        elem.css('padding-bottom', mHeight);
      }
    });
  }

  if (divider) {
    setDividerHeight();

    $(window).on('resize', function () {
      setDividerHeight();
    });
  }
};

function playVideo() {
  let videoTrigger = $('.video-cover');

  function loadVideo(video, id) {

      window.YT.ready(function() {
          new window.YT.Player(video, {
              height: "787",
              width: "1400",
              videoId: id,
              events: {
                  onReady: onPlayerReady,
                  onStateChange: onPlayerStateChange
              }
          });
      });

      function onPlayerReady(event) {
          event.target.playVideo();
      }

      function onPlayerStateChange(event) {
          var videoStatuses = Object.entries(window.YT.PlayerState);
      }
  }

  function stopVideo() {
      player.stopVideo();
      player.seekTo(0, true);
  }

  if (videoTrigger.length > 0) {
      videoTrigger.click(function() {
          let embedId = $(this).data("embedid"),
              videoId = $(this).data("videoid"),
              video = $(this).siblings().find(".video");

          $(this).fadeOut('slow');

          $.getScript("https://www.youtube.com/iframe_api", function() {
              loadVideo(videoId, embedId);
          });

          video.show();
      });
  }
};

//toogle category description
$(function () {
  if ($('.tax-product_cat').length > 0 || $('.woocommerce-shop').length > 0) {
    if ($('.category-desc').length > 0) {
      let firstPart = '';
      let lastPart = '';
      let content = $('.category-desc').html();
      if (content.indexOf("<!--more-->") >= 0) {
        let categoryDescArray = content.split("<!--more-->");
        firstPart = categoryDescArray[0];
        lastPart = categoryDescArray[1];
        $('.category-desc').empty();
        $('.category-desc').append(`<div class="first-part">${firstPart}</div>`);
        $('.category-desc .first-part').append(`<button class="more">Więcej ></button>`);
        $('.category-desc').append(`<div class="last-part" style="display: none;">${lastPart}</div>`);
        $('.category-desc .last-part').append(`<button class="less">Mniej <</button>`);
      }


      $('.category-desc').show();

      $('.category-desc .more').on("click", function (e) {
        $(this).hide();
        $('.category-desc .last-part').slideDown();
      });


      $(document).on("click", ".category-desc .less", function(){
        $('.category-desc .last-part').slideUp();
        setTimeout(function(){
          $('.category-desc .more').show();
          }, 300);
      });
    }
  }
});

//toggle submenu
$(function () {
  $('.menu-item-has-children:not(".wpml-ls-menu-item")').on("click", function (e) {
    e.stopPropagation();
    if ($(window).width() < 1024) {
      $(this).toggleClass("active_submenu");
      $(this).find('>ul').slideToggle();
    }
  });

  $('.menu-item-has-children:not(".wpml-ls-menu-item")').hover(
    function() {
      if ($(window).width() >= 1024) {
        $(this).addClass("active_submenu");
        $(this).find('>ul').fadeIn();
      }
    }, function() {
      if ($(window).width() >= 1024) {
        $(this).removeClass("active_submenu");
        $(this).find('ul').hide();
      }
    }
  );
});

//menu on scroll
function headerControl() {
	let currentScrollPos = window.pageYOffset,
		header = $('.js-header');

	$(window).scroll(function() {
		currentScrollPos = window.pageYOffset;
		if (currentScrollPos >= 110) {
			header.addClass('fixed-on-scroll');
		} else {
			header.removeClass('fixed-on-scroll');
		}
	});
};

function menuControl() {
	let burger = $('.js-toggle-menu'),
		navigation = $('.js-menu-header'),
    windowWidth = $(window).innerWidth();

  burger.click(function () {
    toggleMenu();
  });

	function toggleMenu() {
		if (navigation.hasClass('opened')) {
			closeMenu();
		} else {
			openMenu();
		}
	}

	function openMenu() {
		$('body').addClass('fixed');
		burger.addClass('active');
		navigation.addClass('opened');
	}

	function closeMenu() {
		$('body').removeClass('fixed');
		burger.removeClass('active');
		navigation.removeClass('opened');
	}

	$(document).on('click', function (e) {
		if (!navigation.is(e.target) // if the target of the click isn't the container...
			&& navigation.has(e.target).length === 0 // ... nor a descendant of the container
			&& !burger.is(e.target) // if the target of the click isn't the container...
			&& burger.has(e.target).length === 0 // ... nor a descendant of the container
      && !$('.search-box').is(e.target)
      && $('.search-box').has(e.target).length === 0
    )
		{
			closeMenu();
		}
	});
};

function loadProductsFiles(){
  var loadProductsFilesRequest = null;
  let wrap = $("#products-files-list-ajax");

  if (wrap.length > 0) {
    function loadFiles(category){
      if (loadProductsFilesRequest) {
        loadProductsFilesRequest.abort();
        wrap.removeClass('loading');
      }

      if (wrap.hasClass('loading')) return false;

      wrap.addClass('loading');
      loadProductsFilesRequest = $.post(ajaxUrl, {
        action: 'loadFiles',
        category: category
      }, function (response) {
        if (response) {
          wrap.html(response).removeClass('loading');
        } else {
          wrap.html(`
            <section class="products-files-list">
                <p class="no-data">
                    ${wrap.data('info')}
                </p>
            </section>
          `).removeClass('loading');
        }
      });
    }
    $(function(){
      $(document).on("click", ".products-files .js-load-files", function () {
        let btn = $(this);

          let category = btn.data('category');

        btn.addClass('active');
        btn.siblings().removeClass('active');
        loadFiles(category);

        $('html, body').animate({
          scrollTop: $("#products-files-list-ajax").offset().top - 200
        }, 300);
      });
    });
  }
}

function loadFaq(){
  var loadFaqRequest = null;
  let wrap = $("#faq-list-ajax");

  if (wrap.length > 0) {
    function loadFaq(category){
      if (loadFaqRequest) {
        loadFaqRequest.abort();
        wrap.removeClass('loading');
      }

      if (wrap.hasClass('loading')) return false;

      wrap.addClass('loading');
      loadFaqRequest = $.post(ajaxUrl, {
        action: 'loadFaq',
        category: category
      }, function (response) {
        if (response) {
          wrap.html(response).removeClass('loading');
        } else {
          wrap.html(`
            <section class="faq-list">
                <p class="no-data">
                    ${wrap.data('info')}
                </p>
            </section>
          `).removeClass('loading');
        }
      });
    }
    $(function(){
      $(document).on("click", ".faq .js-load-faq", function () {
        let btn = $(this);

        let category = btn.data('category');

        btn.addClass('active');
        btn.siblings().removeClass('active');
        loadFaq(category);

        $('html, body').animate({
          scrollTop: $("#faq-list-ajax").offset().top - 200
        }, 300);
      });
    });
  }

  $(function(){
    $(document).on("click", ".faq .faq-list .item .title", function () {
      $(this).parent().toggleClass('active');
      $(this).parent().siblings().removeClass('active');
    });
  });
}

function setBoldSingleProductTitleCategoryName(){
  var category_title = [
    'Płyta gazowa',
    'Piekarnik',
  ];

  var woocommerce_product_title = $('.woocommerce-custom-single-product-title__wrapper .product_title');

  woocommerce_product_title.each(function(){
    var product_title = $(this);

    category_title.forEach(function(ct){

      if (product_title.text().indexOf(ct) >= 0) {
        var s = `<b style='font-weight: 500;'>${product_title.text().substr(product_title.text().indexOf(ct), ct.length)}</b><br>${product_title.text().substring(ct.length)}`;
        product_title.html(s);
      }

    });
  })
}

function singleProductTabToggle(){
  $('.woocommerce-custom-single-product-tab .tab_toggle').on('click', function(){
    $(this).closest('.woocommerce-custom-single-product-tab').find('.tab_content').slideToggle();
    $(this).toggleClass('hidden')
  })
}

function toggleHiddenAttributes(){
  $('.toggle-attributes').on('click', function(){
    if(!$(this).hasClass('active')){
      $(this).addClass('active');
      $('.woocommerce-custom-single-product-attributes .hidden-content').slideDown();
    } else {
      $(this).removeClass('active');
      $('.woocommerce-custom-single-product-attributes .hidden-content').slideUp();
    }
  });
}

function svgIconsColor() {
  let tile = $('.custom-icon-color');

  if (tile.length > 0) {
    tile.each(function () {
      let iconColor = $(this).data('iconcolor');

      if (iconColor.length > 0) {
        $(this).children('.icon.svg').children('path').css("fill", iconColor);
      }
    });
  }
}

function updateCartAfterChange() {
  var timeout;

  $('.woocommerce').on('change', 'input.qty', function () {

    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
      $("[name='update_cart']").trigger("click");
    }, 500);

  });

  $( 'body' ).on( 'added_to_cart', function( e, fragments, cart_hash, this_button ) {
    makeSvgInline($("img.svg.icon__cart"));
  } );
}

function sliders() {
  let logosSlider = $('.logos-slider');

  if (logosSlider.length > 0) {
    logosSlider.slick({
      mobileFirst: true,
      slidesToShow: 2,
      arrows: true,
      autoplay: true,
      duration: 2000,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 3
        }
      },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 5
          }
        }
      ]
    });
  }

  let mainSlider = $('.products-slider');

  if (mainSlider.length > 0) {
    mainSlider.slick({
      mobileFirst: true,
      autoplay: true,
      duration: 5000,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
          }
        }
      ]
    });
  }
}

function filesUploads() {
  $('.wpcf7-form').on('change', '.upload input[type=file]', function () {
    let filename = $(this).val().replace(/.*(\/|\\)/, '');
    if (filename.length > 0) {
      $(this).parents('.upload').siblings('span').remove();
      $(this).parents('.upload').after(`<span class="filename">${filename}<i class="file-delete"></i></span>`);
    }
  });

  $('.wpcf7-form').on('click', '.file-delete', function (e) {
    $(this).parent().siblings('.upload').find('input[type=file]').val('');
    $(this).parent().remove();
  });
}

function checkIfMenuItemIsActive() {
  let currentUrl = window.location.href;
  if (currentUrl.indexOf("/sklep/") > -1 || currentUrl.indexOf("/produkt/") > -1) {
    $('.js-menu-header .menu-item-shop').addClass('current_page_parent');
  } else {
    $('.js-menu-header .menu-item-shop').removeClass('current_page_parent');
  }

  if (currentUrl.indexOf("/blog/") > -1) {
    $('.js-menu-header .menu-item-blog').addClass('current_page_parent');
  } else {
    $('.js-menu-header .menu-item-blog').removeClass('current_page_parent');
  }
}

$(function(){
  init();
  headerControl();
  menuControl();
  langSwitcher();
  searchBox();
  dividerHeight();
  playVideo();
  loadProductsFiles();
  loadFaq();
  setBoldSingleProductTitleCategoryName();
  singleProductTabToggle();
  toggleHiddenAttributes();
  svgIconsColor();
  updateCartAfterChange();
  sliders();
  filesUploads();
  checkIfMenuItemIsActive();
})

$(function() {
  if( $(".posts-wrap").length > 0 ) {
    let loadBlogRequest = null,

      loadBlog = function (category, offset, trigger) {
        let wrap = trigger.parent().siblings(".posts-wrap");
        if (loadBlogRequest) {
          loadBlogRequest.abort();
          wrap.addClass('loading');
          trigger.addClass('disabled');
          loadBlogRequest = $.post(ajaxUrl, {
            action: 'loadBlog',
            category: category,
            offset: offset,
          }, function (response) {
            if (response) {
              wrap.append(response).removeClass('loading');
              trigger.removeClass('disabled');
            }
          });
        }

        if (wrap.hasClass('loading')) {
          return false;
        }

        wrap.addClass('loading');
        trigger.addClass('disabled');
        loadBlogRequest = $.post(ajaxUrl, {
          action: 'loadBlog',
          category: category,
          offset: offset,
        }, function (response) {
          if (response) {
            wrap.append(response).removeClass('loading');
            trigger.removeClass('disabled');
          }
        });
      };

    $(".load-more-posts").on( "click", function() {
      let category = $(this).data('category'),
          count = $(this).data('count') + 3;

      $(this).data('count', count);
      if (count >= $(this).data('total')-1) {
        $(this).attr("disabled", 'disabled');
      } else {
        loadBlog(category, count, $(this));
      }
    });
  }
});

$(function() {
  let priceFreeTranslate = $('.woocommerce-cart-form__cart-item .product-price');
  priceFreeTranslate.each(function(){
    if ($(this).text().indexOf("Free") >= 0) {
      $(this).text('Za darmo');
    }
  });
});
